import produce from 'immer';
import constants from 'constants/hub-page-revision';

const initialState = {
  revision: {},
  saving: false,
  saveError: '',
  activeHubPageKey: 'sections',
  activeProjectTab: 0,
  errors: {},
  uploadingFile: false,
  hasUnsavedChanges: false,
  fileUploadError: '',
  uploadedFileUrl: '',
  currentSectionId: '',
  showTemplatesDrawer: false,
  showAddNewSectionDrawer: false,
  nonce: '',
};

export default produce((draft, action) => {
  switch (action.type) {
    case constants.SAVE_HUB_PAGE_REVISION_REQUEST:
      draft.saving = true;

      break;

    case constants.SAVE_HUB_PAGE_REVISION_SUCCESS:
      draft.saving = false;
      draft.saveError = '';
      draft.errors = {};
      draft.revision.id = action.id;
      draft.revision.meta = action.meta;
      draft.revision.sections = action.sections;
      draft.hasUnsavedChanges = false;

      break;

    case constants.SAVE_HUB_PAGE_REVISION_FAILURE:
      draft.saving = false;
      draft.saveError = action.errorMessage;
      draft.errors = action.errors;

      break;

    case constants.SET_HUB_PAGE_REVISION:
      draft.revision = action.revision;
      break;

    case constants.SET_HUB_PAGE_REVISION_CURRENT_SECTION_ID:
      draft.currentSectionId = action.id;

      break;

    case constants.SHOW_HUB_PAGE_TEMPLATES_DRAWER:
      draft.showTemplatesDrawer = action.value;

      break;

    case constants.SHOW_HUB_PAGE_ADD_NEW_SECTIONS_DRAWER:
      draft.showAddNewSectionDrawer = action.value;

      break;

    case constants.UPDATE_HUB_PAGE_REVISION_SECTION_THEME:
      draft.revision.sections[action.sectionId].theme[action.key] =
        action.value;
      draft.hasUnsavedChanges = true;

      break;

    case constants.UPDATE_HUB_PAGE_REVISION_SECTION_DATA:
      draft.revision.sections[action.sectionId].data[action.index][action.key] =
        action.value;
      draft.hasUnsavedChanges = true;

      break;

    case constants.ADD_HUB_PAGE_REVISION_SECTION_DATA:
      const section = draft.revision.sections[action.sectionId];
      const dataOfLastItem = section.data[section.data.length - 1];

      // clone of last item and reassign a new id
      const cloneLastItem = JSON.parse(JSON.stringify(dataOfLastItem));
      const id = action.tabId;

      cloneLastItem.id = id.toString();
      draft.revision.sections[action.sectionId].data.push(cloneLastItem);
      draft.hasUnsavedChanges = true;

      break;

    case constants.DELETE_HUB_PAGE_REVISION_SECTION_DATA:
      draft.revision.sections[action.sectionId].data.splice(action.index, 1);
      draft.hasUnsavedChanges = true;

      break;

    case constants.UPDATE_HUB_PAGE_REVISION_SECTION_META:
      draft.revision.sections[action.sectionId].meta[action.key] = action.value;
      draft.hasUnsavedChanges = true;

      break;

    case constants.ADD_NEW_HUB_PAGE_REVISION_SECTION:
      draft.revision.sections.splice(action.position, 0, action.sectionContent);
      draft.hasUnsavedChanges = true;

      break;

    case constants.DELETE_HUB_PAGE_REVISION_SECTION:
      draft.revision.sections.splice(action.position, 1);
      draft.hasUnsavedChanges = true;

      break;

    case constants.SELECT_HUB_PAGE_REVISION_TEMPLATE:
      draft.revision.sections = action.sections;
      draft.revision.meta = action.meta;

      break;

    case constants.UPDATE_HUB_PAGE_REVISION_TEMPLATE_META:
      draft.revision.meta[action.key] = action.value;
      draft.hasUnsavedChanges = true;
      break;

    case constants.UPLOAD_HUB_PAGE_REVISION_IMAGE_REQUEST:
      draft.uploadingFile = true;

      break;

    case constants.UPLOAD_HUB_PAGE_REVISION_IMAGE_SUCCESS:
      draft.uploadingFile = false;
      draft.fileUploadError = '';
      draft.uploadedFileUrl = action.imageUrl;
      draft.hasUnsavedChanges = true;

      break;

    case constants.UPLOAD_HUB_PAGE_REVISION_IMAGE_FAILURE:
      draft.uploadingFile = false;
      draft.fileUploadError = action.errorMessage;

      break;

    case constants.MOVE_HUB_PAGE_REVISION_SECTION:
      draft.revision.sections = action.sections;
      draft.currentSectionId = action.toIndex;
      draft.hasUnsavedChanges = true;
      break;

    case constants.REORDER_HUB_PAGE_ITEMS:
      draft.revision.sections[action.sectionId].data = action.data;
      draft.hasUnsavedChanges = true;
      break;

    case constants.CHANGE_HUB_PAGE_SECTION_KEY:
      draft.activeHubPageKey = action.key;

      break;

    case constants.UPDATE_HUB_PAGE_REVISION:
      draft.revision[action.key] = action.value;
      draft[action.key] = action.value;

      break;

    case constants.UPDATE_HUB_PAGE_REVISION_NONCE:
      draft.nonce = action.value;

      break;

    case constants.RESET_HUB_PAGE_REVISION:
      return initialState;
  }
}, initialState);
